<template>
  <div>
    <Header
      :showBackButton="true"
      :showLogo="true"
      :showMenuIcon="false"
      :showDesktopNavbar="false"
      :menuColor="'var(--primary)'"
      class="page-anim1"
    />

    <section>
      <div class="container">
        <div class="title mt-2 text-center animated zoomIn">Criar conta</div>

        <div
          v-show="step === 1"
          class="user-data-holder mt-2 animated fadeInUp"
        >
          <div class="input-item">
            <span>Nome *</span>
            <input v-model="user.name" type="text" />
          </div>
          <div class="input-item">
            <span>E-mail *</span>
            <input v-model="user.email" type="email" />
          </div>
          <div class="input-item">
            <span>Telefone</span>
            <input v-model="user.phone" type="text" />
          </div>
          <div class="input-item">
            <span>Empresa</span>
            <input v-model="user.company" type="text" />
          </div>
          <div class="flex-between">
            <div class="input-item">
              <span>Estado *</span>
              <select v-model="user.state">
                <option value="AC">Acre</option>
                <option value="AL">Alagoas</option>
                <option value="AP">Amapá</option>
                <option value="AM">Amazonas</option>
                <option value="BA">Bahia</option>
                <option value="CE">Ceará</option>
                <option value="DF">Distrito Federal</option>
                <option value="ES">Espírito Santo</option>
                <option value="GO">Goiás</option>
                <option value="MA">Maranhão</option>
                <option value="MT">Mato Grosso</option>
                <option value="MS">Mato Grosso do Sul</option>
                <option value="MG">Minas Gerais</option>
                <option value="PA">Pará</option>
                <option value="PB">Paraíba</option>
                <option value="PR">Paraná</option>
                <option value="PE">Pernambuco</option>
                <option value="PI">Piauí</option>
                <option value="RJ">Rio de Janeiro</option>
                <option value="RN">Rio Grande do Norte</option>
                <option value="RS">Rio Grande do Sul</option>
                <option value="RO">Rondônia</option>
                <option value="RR">Roraima</option>
                <option value="SC">Santa Catarina</option>
                <option value="SP">São Paulo</option>
                <option value="SE">Sergipe</option>
                <option value="TO">Tocantins</option>
              </select>
            </div>

            <div class="input-item">
              <span>Cidade</span>
              <input v-model="user.city" type="text" />
            </div>
          </div>

          <div class="flex-between">
            <div class="input-item">
              <span>Senha *</span>
              <input v-model="user.password" type="password" />
            </div>
            <div class="input-item">
              <span>Confirmar senha *</span>
              <input v-model="user.password2" type="password" />
            </div>
          </div>

          <div class="flex-start mt-2 nowrap">
            <input
              v-model="user.emailNotification"
              type="checkbox"
              id="emailNotification"
              class="mr-1"
            />
            <label for="emailNotification"
              >Quero receber notícias e novidades da Siber por e-mail.</label
            >
          </div>

          <div class="terms">
            <p>
              <span>
                Ao continuar você confirma que <b>leu e está de acordo</b> com os nossos
              </span>
              <router-link 
                to="/termos"
                :class="{active: $route.name == 'Terms'}">
                <Chat />
                <p>TERMOS DE USO</p>
              </router-link>
              <span>e</span>
              <a
                rel="noopener noreferrer"
                href="https://siber-app.s3.sa-east-1.amazonaws.com/politica_de_privacidade.pdf"
                target="_blank"
                >POLÍTICAS DE PRIVACIDADE</a
              >.
            </p>
          </div>

          <div
            @click="save"
            class="btn desktop4 mx-auto mt-2"
            style="background-color: var(--primary)"
          >
            <span v-show="!loading">Criar conta</span>
            <div v-show="loading" class="loading2 white"></div>
          </div>

          <div class="mt-2 text-center animated zoomIn">
            Já possui cadastro?
          </div>

          <div
            @click="$router.push('/login')"
            class="btn desktop4 mx-auto"
            style="background-color: var(--blue1)"
          >
            <span>Fazer login</span>
          </div>
        </div>

        <transition enter-active-class="animated fadeInUp">
          <div
            v-show="step === 2"
            class="user-data-holder mt-2 animated fadeInUp"
          >
            <div class="title text-center">Confirmação de e-mail</div>
            <p class="desc text-center mt-2">
              Enviamos um código de confirmação para o seu e-mail. (Pode levar
              alguns minutos para chegar).
            </p>
            <p class="desc text-center mt-1">
              Por favor, informe o código abaixo.
            </p>

            <div class="input-item mt-2">
              <span class="text-center">Código de confirmação *</span>
              <input
                v-model="confirmationCode"
                type="text"
                class="text-center"
              />
            </div>

            <div class="flex-center">
              <div
                @click="validateConfirmationCode"
                class="btn"
                style="background-color: var(--primary)"
              >
                <span v-show="!loading">Confirmar código</span>
                <div v-show="loading" class="loading2 white"></div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </section>
  </div>
</template>
<script>
import Header from "../components/Header";
import validators from "@/util/validators";
import authentication from "../util/authentication";
import http from "@/http";

export default {
  name: "Home",
  components: {
    Header,
  },
  data() {
    return {
      loading: false,
      user: {
        name: "",
        email: "",
        phone: "",
        company: "",
        state: "",
        city: "",
        emailNotification: false,
        password: "",
        password2: "",
        code: "",
      },
      termsLink: "",
      step: 1,
      confirmationCode: "",
    };
  },
  async created() {
    if (this.$route.query.validateEmail) {
      this.step = 2;
      this.user = { ...this.user, ...JSON.parse(localStorage.userLogin) };
    }

    let terms = await http.get("termsOfUse");
    this.termsLink = terms.data.path;
  },
  methods: {
    validateConfirmationCode() {
      if (this.confirmationCode.length < 4) {
        this.showWarning(
          "Código de verificação *",
          "Por favor, verifique se o código informado está correto."
        );
        return;
      }

      this.user.code = this.confirmationCode;

      this.loading = true;

      authentication
        .login(this.user)
        .then((response) => {
          if (response) {
            let user = {
              e: btoa(this.user.email),
              p: btoa(this.user.password),
            };
            localStorage.retryL = JSON.stringify(user);
            this.$swal({
              toast: true,
              icon: "success",
              title: "E-mail confirmado!",
              position: "top-right",
              showConfirmButton: false,
              timer: 4000,
            });
            this.$router.push("/home");
          }
        })
        .catch((err) => {
          console.log(err);
          this.warningMessage =
            "Algo deu errado. Por favor, tente novamente em alguns instantes.";
        })
        .finally(() => {
          this.retryLogin = false;
          this.loading = false;
        });
    },
    showWarning(title, text) {
      this.$swal({
        icon: "warning",
        title: title,
        text: text,
      });
    },
    save() {
      if (this.user.name.length < 4) {
        this.showWarning("Nome *", "Por favor, informe o seu nome.");
        return;
      }
      if (!validators.validateEmail(this.user.email)) {
        this.showWarning("E-mail *", "Por favor, informe um e-mail válido.");
        return;
      }
      if (!this.user.state) {
        this.showWarning("Estado *", "Por favor, selecione o seu estado.");
        return;
      }
      if (this.user.password.length < 5) {
        this.showWarning(
          "Senha *",
          "Por favor, informe uma senha com pelo menos 5 caracteres."
        );
        return;
      }
      if (this.user.password2 !== this.user.password) {
        this.showWarning(
          "Confirmar senha *",
          "Sua confirmação de senha está diferente da sua senha."
        );
        return;
      }

      this.loading = true;

      http
        .post("user", this.user)
        .then((response) => {
          localStorage.user = JSON.stringify(response.data);
          this.step = 2;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  position: relative;
  color: var(--dark4);
  margin: 15px 0 0 0;
}

.user-data-holder {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
  padding: 1.2em 5% 2em 5%;
  border-radius: 12px;
  box-shadow: 0 25px 35px 0 rgba(0, 0, 0, 0.1);
}

label {
  font-size: 1.05em;
  color: var(--secondary);
  letter-spacing: 1px;
}

.terms {
  position: relative;
  margin-top: 25px;
  p {
    font-size: 1.05em;
    line-height: 1.5em;
    letter-spacing: 1px;
    span {      
      float: left;
      min-width: 20px;
      text-align: center;
    }
    a, a > p {
      float: left;
      font-weight: bolder;
      color: var(--primary);
      text-decoration: underline;
    }
  }
}

.desc {
  font-size: 1.15em;
}
</style>
